<template>
  <ScrollContainer>
    <Container>
      <CardSection>
        <CardList>
          <CardListItem>
            <SpinContainer :loading="isProcessing" class="m-5 processingContainer">
              <span> <font-awesome-icon icon="gear" />&nbsp; Processing... </span>
            </SpinContainer>
          </CardListItem>
        </CardList>
      </CardSection>
    </Container>
  </ScrollContainer>
</template>

<script>
export default {
  name: 'AccountingCallback',
  data() {
    return {
      isProcessing: true
    }
  },
  methods: {
    async setupConnection(code, id) {
      const { result } = await this.$store.dispatch('ExportToken/saveToken', {
        accessCode: code,
        realmId: id,
        companyId: this.defaultedCompanyId
      })
      if (result.object.success) {
        this.$router.push({ name: 'Accounting' })
      }
    }
  },
  mounted() {
    if (
      this.$route.query.code &&
      this.$route.query.code.length > 0 &&
      this.$route.query.realmId &&
      this.$route.query.realmId.length > 0
    ) {
      this.setupConnection(this.$route.query.code, this.$route.query.realmId)
    }
  },
  computed: {
    defaultedCompanyId() {
      return this.$store.state.session.company.company_id
    }
  }
}
</script>

<style scoped>
.processingContainer {
  font-weight: bold;
  text-align: center;
}
</style>
